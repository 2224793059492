import validate from '../../constants/Validator';
import {firewallReplacement} from "../../constants/Helpers";

export const SET_COMPANY_DATA_FIELD = 'SET_COMPANY_DATA_FIELD'
export const SET_COMPANY_DATA = 'SET_COMPANY_DATA'
export const SET_COMPANY_ERRORS = 'SET_COMPANY_ERRORS'
export const SET_COMPANY_TYPE = 'SET_COMPANY_TYPE'
export const GET_COMPANY_DATA = 'GET_COMPANY_DATA'
export const GET_ALL_COMPANY_DATA = 'GET_ALL_COMPANY_DATA'


const constraints =

    {
        de_AT: {
            companyName: {
                firewallChars: true,
                length:
                    {
                        minimum: 1,
                        message:
                            "^Dieses Feld darf nicht Leer sein."
                    }
                ,
            }
            ,
            businessName: {
                firewallChars: true,
                length:
                    {
                        minimum: 1,
                        message:
                            "^Dieses Feld darf nicht Leer sein."
                    }
                ,
            }
            ,
            street: {
                firewallChars: true,
                length:
                    {
                        minimum: 1,
                        message:
                            "^Bitte geben Sie eine gültige Straße und Hausnummer an."
                    }
            },
            zipCode: {
                firewallChars: true,
                numericality:
                    {
                        onlyInteger: true,
                        lessThanOrEqualTo:
                            9999,
                        greaterThanOrEqualTo:
                            1000,
                        message:
                            '^Bitte geben Sie eine Postleitzahl zwischen 1000 und 9999 an'
                    }
            },
            city: {
                firewallChars: true,
                length:
                    {
                        minimum: 1,
                        message:
                            "^Dieses Feld darf nicht Leer sein."
                    },
            },
            uid: {
                firewallChars: true,
                format:
                    {
                        pattern: "^ATU[0-9]{8}$|^[0-9]+$",
                        flags:
                            "i",
                        message:
                            "^Bitte geben Sie eine gültige UID z.b.: ATU12345678 an."
                    }
            },
            phone: {
                firewallChars: true,
                phoneNumber:
                    {
                        country: 'AT',
                        message:
                            "^error.validPhoneNumber"
                    },
            },
            email: {
                firewallChars: true,
                email:
                    {
                        message: "^Bitte geben Sie eine gültige E-Mail Adresse an."
                    }
            },
            paymentRecipient: {
                firewallChars: true,
                length:
                    {
                        minimum: 1,
                        message:
                            "^Dieses Feld darf nicht Leer sein."
                    },
            },
            iban: {
                firewallChars: true,
                iban:
                    {
                        message: 'Bitte geben Sie einen gültigen IBAN an.'
                    }
            },
            bic: {
                firewallChars: true,
                bic:
                    {
                        message: 'Bitte geben Sie einen gültigen BIC an.'
                    }
            },
            gisa: {
                firewallChars: true,
                format:
                    {
                        pattern: "^(\\s*|[0-9]+)$",
                        flags:
                            "i",
                        message:
                            "^Bitte geben Sie eine gültige Zahl an."
                    }
            },
            website: {
                firewallChars: true,
                url:
                    {
                        allowLocal: true,
                        allowDataUrl:
                            false,
                        message:
                            "^Bitte geben Sie eine gültige Website mit http:// oder https:// an."
                    }
                ,
            }
            ,
        },
        de_DE: {
            zipCode: {
                firewallChars: true, // Stellen Sie sicher, dass diese Einstellung Ihren Anforderungen entspricht
                numericality: {
                    onlyInteger: true,
                    notValid: {
                        pattern: /^[0-9]{5}$/,
                        message: '^Bitte geben Sie eine gültige fünfstellige deutsche Postleitzahl ein.'
                    },
                    lessThanOrEqualTo: 99998,
                    greaterThanOrEqualTo: 1001,
                    message: '^Bitte geben Sie eine Postleitzahl zwischen 01001 und 99998 an.'
                }
            },
            uid: {
                firewallChars: true,
                format:
                    {
                        pattern: "^DE[0-9]{9}$|^[0-9]+$",
                        flags:
                            "i",
                        message:
                            "^Bitte geben Sie eine gültige UID mit ATU oder ZVR an."
                    }
            },
            phone: {
                firewallChars: true,
                phoneNumber:
                    {
                        country: 'DE',
                        message:
                            "^error.validPhoneNumber"
                    },
            },
        }
    }

export function getConstraint(langKey = 'de_AT') {
    return {...constraints['de_AT'], ...constraints[langKey]};
}


export const setCompanyErrors = (data) => ({
    type: SET_COMPANY_ERRORS,
    data,
});

export const setCompanyType = (data) => ({
    type: SET_COMPANY_TYPE,
    data,
});

export const setCompanyDataField = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data
});
export const setCompanyData = (data,langKey) => ({
    type: SET_COMPANY_DATA,
    data,
    langKey
});


export const getCompanyData = () => ({
    type: GET_COMPANY_DATA,
});

export const setCompanyBic = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data,
    field: 'bic',
});

export const setCompanyBusinessName = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data,
    field: 'businessName',
});

export const setCompanyCity = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data,
    field: 'city',
});

export const setCompanyEmail = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data,
    field: 'email',
});

export const setCompanyIban = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data,
    field: 'iban',
});


export const setCompanyPaymentRecipient = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data,
    field: 'paymentRecipient',
});

export const setCompanyUid = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data,
    field: 'uid',
});

export const setCompanyZipCode = (data, langKey) => ({
    type: SET_COMPANY_DATA_FIELD,
    data,
    field: 'zipCode',
    langKey
});

export const setCompanyStreet = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data,
    field: 'street',
});

export const setCompanyPhone = (data, langKey) => ({
    type: SET_COMPANY_DATA_FIELD,
    data,
    field: 'phone',
    langKey
});

export const setCompanyName = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data,
    field: 'companyName',
});

export const setGisaNumber = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data: data,
    field: 'gisa',
});
export const setWebsite = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data,
    field: 'website',
});


export function validateCompanyData(data, langKey) {

    if (validate.isEmpty(data.website)) {
        delete data['website'];
    }

    if (langKey === 'de_DE') {
        if (validate.isEmpty(data.uid)) {
            delete data['uid'];
        }
    }


    let validation = validate({...data}, getConstraint(langKey))

    if (data.hasOwnProperty('website') && validate.isEmpty(data.website)) {
        delete validation['website'];
    }

    return validation;
}

const initialState = {
    data: {
        companyType: 'exist',
        companyName: '',
        businessName: '',
        street: '',
        zipCode: '',
        city: '',
        uid: '',
        phone: '',
        email: '',
        paymentRecipient: '',
        iban: '',
        bic: '',
        gisa: '',
        website: '',
    },
    error: {
        companyName: false,
        businessName: false,
        street: false,
        zipCode: false,
        city: false,
        uid: false,
        phone: false,
        email: false,
        paymentRecipient: false,
        iban: false,
        bic: false,
        gisa: false,
        website: false,
    }
}


export default (state = initialState, action) => {
    let {data, field, langKey = 'de_AT'} = action;
    let skipValidation = false;
    let validation = null;

    switch (action.type) {
        case SET_COMPANY_DATA:
            let validatedCompany = validateCompanyData(data,langKey);
            state['error'] = {...state['error'], ...validatedCompany};
            state['data'] = data;
            return {...state};
        case SET_COMPANY_DATA_FIELD:

            if (field === 'website' && validate.isEmpty(data)) {
                skipValidation = true;
            }

            if (langKey === 'de') {
                if (field === 'uid' && validate.isEmpty(data)) {
                    skipValidation = true;
                }
            }

            if (field === 'website' && validate.isEmpty(data)) {
                skipValidation = true;
            }

            if (skipValidation === false) {
                let validationObj = {[field]: data};
                validation = validate(validationObj, getConstraint(langKey));
                state["error"][field] = validation ? state["error"][field] = validation[field].join(', ') : false;
            } else {
                state["error"][field] = false;
            }

            state['data'][field] = firewallReplacement(data);
            return {...state};

        case SET_COMPANY_ERRORS:
            state['error'] = {...state['error'], ...data};
            return {...state};
        case SET_COMPANY_TYPE:
            state['data']['companyType'] = data;
            return {...state};
        default:
            return state;
    }
}
