import {all, call, put, select} from 'redux-saga/effects';
import {setCompanyData, setCompanyType} from "../reducers/CompanyData";
import {requestSaveFormData} from "../request/Form";
import {requestGetContactPersons} from "../request/ContactPerson";
import {setStep} from "../reducers/Stepper";
import {setContacts} from "../reducers/ContactPersons";
import {setFormDataStatus} from "../reducers/Form";
import {requestGetCompanyData} from "../request/Company";
import {requestGetServices} from "../request/Services";
import {setPlaces} from "../reducers/Places";
import {requestGetPlaces} from "../request/Places";
import {setProductServices} from "../reducers/Services";
import {setError} from "../reducers/Error";
import TagManager from "react-gtm-module";
import {tagManagerArgs} from "../../constants/Config";
import store from "../store";


export function* handleSaveForm(action) {

    try {
        const response = yield call(requestSaveFormData, action.formData);
        const {data} = response;
        return window.location = '/finish';
    } catch (e) {
        yield put(setError('Die Anfrage konnte nicht gespeichert werden, bitte versuchen Sie es erneut.'));
        let dataLayer = {
            event: 'gtm.pageError', 'gtm.errorMessage': e.message, 'gtm.errorLineNumber': e.lineNumber
        };
        TagManager.initialize({...tagManagerArgs, dataLayer});
    }
}

export function* handleGetFormData(action) {
    let error = [];
    const langKey = store.getState().country.i18n.language;

    yield put(setFormDataStatus('init'));
    try {
        const companyResponse = yield call(requestGetCompanyData);
        yield put(setCompanyData(companyResponse.data.data,langKey));
    } catch (e) {
        console.debug(e);
        error.push('- Firmendaten')
    }

    try {
        const contactPersonsResponse = yield call(requestGetContactPersons);
        yield put(setContacts(contactPersonsResponse.data.data,langKey));
    } catch (e) {
        console.debug(e);
        error.push('- Kontaktpersonen')
    }

    try {
        const serviceResponse = yield call(requestGetServices);
        yield put(setProductServices(serviceResponse.data.data,langKey));
    } catch (e) {
        console.debug(e);
        error.push('- Produkte')
    }

    try {
        const placesResponse = yield call(requestGetPlaces);
        const getServices = state => state.services;
        const services = yield select(getServices);
        const places = placesResponse.data.data.map(function (v) {
            v.products = services.data.product;
            return v;
        })
        yield put(setPlaces(places,langKey));
    } catch (e) {

        console.debug(e);
        error.push('- Standorte');
    } finally {

        if (error.length > 0) {
            let message = 'Folgende Daten konnten nicht synchronisiert werden:\n\r';
            message += error.join('\n\r');
            yield put(setError(message));
        }

        yield all([put(setCompanyType('exist')), put(setStep(0)), put(setFormDataStatus('done')),]);
    }


}

