import {call, put} from 'redux-saga/effects';
import {setCompanyData} from "../reducers/CompanyData";
import {requestGetCompanyData} from "../request/Company";

export function* handleGetCompanyData(action) {
    try {
        const response = yield call(requestGetCompanyData);
        const {data} = response;
        yield put(setCompanyData(data));
    } catch (e) {
    }
}


