import companyReducer from './reducers/Company';
import companyDataReducer from './reducers/CompanyData';
import serviceReducer from './reducers/Services';
import placesReducer from './reducers/Places';
import contactPersonReducer from './reducers/ContactPersons';
import agreementReducer from './reducers/Agreement';
import contactsReducer from './reducers/Contacts';
import errorReducer from './reducers/Error';
import stepperReducer from './reducers/Stepper';
import apNumberReducer from './reducers/ApNumber';
import maintenanceReducer from './reducers/Maintenance';
import formStatusReducer from './reducers/Form';
import palaceModalReducer from './reducers/PlaceModal';
import deleteReducer from './reducers/Delete';
import bearerTokenReducer from './reducers/Token';
import affiliateReducer from './reducers/Affiliate';
import verifyReducer from './reducers/Verify';
import countryDetectorReducer from './reducers/CountryDetector';
import {createRouterReducer} from "@lagunovsky/redux-react-router";
import {history} from "./store";
import { i18nReducer } from "react-redux-i18n";

export const customReducers = {
    company: companyReducer,
    companyData: companyDataReducer,
    step: stepperReducer,
    contacts: contactsReducer,
    token: bearerTokenReducer,
    error: errorReducer,
    verify: verifyReducer,
    palaceModal: palaceModalReducer,
    contactPersons: contactPersonReducer,
    services: serviceReducer,
    places: placesReducer,
    agreement: agreementReducer,
    apNumber: apNumberReducer,
    formStatus: formStatusReducer,
    delete: deleteReducer,
    affiliate: affiliateReducer,
    maintenance: maintenanceReducer,
    country: countryDetectorReducer,
    i18n: i18nReducer
};