import validate from '../../constants/Validator';
import {getProducts} from "../../Components/Form/Elements/ProductPass/ProductPasses";

export const CHANGE_SERVICE = 'CHANGE_SERVICE'
export const CHANGE_PRODUCT = 'CHANGE_PRODUCT'
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT'
export const ADD_PRODUCT = 'ADD_PRODUCT'
export const SET_PRODUCT_SERVICES = 'SET_PRODUCT_SERVICES'
export const SET_PRODUCT_SERVICE_ERRORS = 'SET_PRODUCT_SERVICE_ERRORS'
export const GET_SERVICES = 'GET_SERVICES'


export function getServiceMapping(langKey) {
    let products = getProducts(langKey); // Assuming getProducts() returns an array of product objects

    // Use the reduce method to transform the products array
    return products.reduce((acc, product) => {
        // Check if the product's name is already a key in the accumulator (acc)
        // If not, initialize it with an empty array
        if (!acc[product.name]) {
            acc[product.name] = [];
        }

        // Assuming product.serviceMapping is an array, we spread it into the existing array
        // This is where you aggregate the serviceMapping arrays from each product into a single array
        // under the product's name key in the accumulator object
        acc[product.name].push(...product.serviceMapping);

        // Return the accumulator for the next iteration
        return acc;
    }, {}); // Initialize the accumulator as an empty object
}


const constraints = {
    service: {
        length: {
            minimum: 1, message: "^Bitte geben Sie mindestens 1 Geschäftsbereich an."
        },
    }, product: {
        length: {
            minimum: 1, message: "^Bitte geben Sie mindestens 1 Produkt an."
        },
    }
}

export const changeService = (data, langKey) => ({
    type: CHANGE_SERVICE, data, langKey
});

export const setProductServices = (data, langKey) => ({
    type: SET_PRODUCT_SERVICES, data, langKey
});

export const getServices = () => ({
    type: GET_SERVICES,
});

export const changeProduct = (data) => ({
    type: CHANGE_PRODUCT, data
});

export const removeProduct = (data) => ({
    type: REMOVE_PRODUCT, data
});

export const addProduct = (data) => ({
    type: ADD_PRODUCT, data
});

export const setProductServiceErros = (data) => ({
    type: SET_PRODUCT_SERVICE_ERRORS, data,
});


const initialState = {
    data: {
        service: [], product: [], locked: [], lockedService: []
    }, error: {
        service: false, product: false
    }
}

export function validateProductServices(data) {
    return validate({...data.data}, constraints);
}

const filterProduct = (products, product) => {
    // remove existing tag
    return products.filter(function (v, k) {
        return v !== product;
    });
}

const getAllowedProducts = (services, locked, langKey) => {
    let allowedProducts = [];

    if (services.length === 0) {
        return allowedProducts;
    }

    // search allowed products
    services.forEach(function (v, i) {
        for (const [s, si] of Object.entries(getServiceMapping(langKey))) {
            if ((si.includes(v) && !allowedProducts.includes(s))) {
                allowedProducts.push(s);
            }
        }
    });

    locked.forEach(function (v, i) {
        if (!allowedProducts.includes(v)) {
            allowedProducts.push(v);
        }
    })

    return allowedProducts;

}

export default (state = initialState, action) => {
    const {data, field, langKey} = action;
    let validation = null;
    let product = state['data']['product'];

    switch (action.type) {
        case CHANGE_SERVICE:

            let service = [...state['data']['service']];
            let lockedService = state['data']['lockedService'];
            if (service.includes(data)) {
                service = filterProduct(service, data);
            } else {
                service.push(data);
            }

            lockedService.forEach(function (v, i) {
                if (v.includes(data) && !service.includes(data)) {
                    service.push(data);
                }

                return v;
            })

            state['data']['service'] = service;
            state['data']['lockedService'] = lockedService;
            state['data']['product'] = getAllowedProducts(service, state['data']['locked'], langKey);

            // filter product if disabled
            return {...state};
        case CHANGE_PRODUCT:
            if (product.includes(data)) {
                state['data']['product'] = filterProduct(product, data);
            } else {
                // add tag
                product.push(data);
                state['data']['product'] = product;
            }
            return {...state};

        case ADD_PRODUCT:
            product.push(data);
            state['data']['product'] = product;
            return {...state};
        case SET_PRODUCT_SERVICES:
            state['data']['lockedService'] = data.services;
            state['data']['locked'] = data.products;
            state['data']['product'] = getAllowedProducts(data.services, data.products, langKey);
            state['data']['service'] = data.services;
            return {...state};
        case REMOVE_PRODUCT:
            state['data']['product'] = filterProduct(product, data);
            return {...state};
        case SET_PRODUCT_SERVICE_ERRORS:
            if (data === undefined) {
                state['error']['service'] = false;
                state['error']['product'] = false;
                return {...state}
            }

            state['error']['service'] = data.hasOwnProperty('service') ? data['service'].join(', ') : false;
            state['error']['product'] = data.hasOwnProperty('product') ? data['product'].join(', ') : false;
            return {...state};
        default:
            return state;
    }
}
