import axios from 'axios';
import {API_URL} from '../../constants/Config'

export function getMaintenance(config,country) {
    return axios.request({
        ...config,
        method: 'get',
        url: API_URL + '/maintenance/' + country,
    })
}
