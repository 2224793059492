export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL; // default backend url
export const API_TOKEN = process.env.REACT_APP_API_TOKEN;
export const API_URL = BACKEND_URL + '/api/v1'; // for versioning
export const TAG_MANAGER_ID = 'GTM-5F9BDKJ'; // for versioning
export const COUNTRY_SWITCH_ENABLED = String(process.env.REACT_APP_COUNTRY_SWITCH_ENABLED).toLowerCase() === 'true';
export const tagManagerArgs = {
    gtmId: TAG_MANAGER_ID
}

export const CONTACT_PERSON_TAGS = [
    {key: 'main', label: 'Hauptverantwortlich (nur eine Person)'},
    {key: 'invoice', label: 'Rechnung'},
    {key: 'contract', label: 'Vertrag'},
    {key: 'marketing', label: 'Marketing'},
]

export const INDUSTRY_CATEGORIES = ['Apotheke', 'Bäckerei', 'Bar/Pub/Bistro', 'Baumarkt', 'Beauty & Schmuck', 'Blumenhandlung', 'Buchhandlung/Papierwaren', 'Cafe/Kaffeehaus', 'Catering & Kantine', 'Elektronik', 'Fast Food/Imbiss', 'Fleischerei', 'Freizeit', 'Home & Living', 'Hotel', 'KFZ Reparatur/Zubehör', 'Lebensmittel', 'Mode & Schuhe', 'Online Bestell- Zustellservice', 'Optiker', 'Reise', 'Restaurant', 'Skigebiete/Bergbahn', 'Spielwaren', 'Sport', 'Tankstelle', 'Trafik', 'Wellness', 'Zoohandlung',];
export const PAYMENT_PROVIDERS = [
    {
        name: 'Adyen',
        value: 'adyen',
        helpText: 'Die Merchant ID ist 15-stellig und beginnt mit 49875xxxxxxxxxx.',
        country: ['de', 'at']
    },
    {
        name: 'Card Complete',
        value: 'cardComplete',
        helpText: 'Sie finden die Merchant ID als „VU-Nr.” direkt auf einem Händlerbeleg. Sie ist 9-stellig und beginnt mit 100xxxxxx, 000xxxxxx oder 600xxxxxx.',
        country: ['de', 'at']
    },
    {
        name: 'Concardis',
        value: 'concardis',
        helpText: 'Sie finden die Merchant ID als „VU-Nr.” direkt auf einem Visa-Händlerbeleg. Sie ist 9-stellig und beginnt mit 14xxxxxxx.',
        country: ['de', 'at']
    },
    {
        name: 'First Data',
        value: 'firstData',
        helpText: 'Sie können die Merchant ID, genannt „Geschäftspartner-ID”, direkt bei First Data erfragen. Sie ist 9-stellig und beginnt mit 80xxxxxxxx.',
        country: ['de', 'at']
    },
    {
        name: 'Global Payments',
        value: 'globalPayments',
        helpText: 'Sie finden die Merchant ID als „Händler-ID“ direkt auf einem Händlerbeleg. Sie ist 10-stellig und beginnt mit 731xxxxxxx.',
        country: ['at']
    },
    {
        name: 'Hobex',
        value: 'hobex',
        helpText: ' Sie können die Merchant ID, genannt „Retailer-ID”, direkt bei Hobex erfragen. Sie ist 14-stellig und beginnt mit Dxxxxxxxxxxxxx oder 9-stellig und beginnt mit 35xxxxxxx.',
        country: ['de', 'at']
    },
    {
        name: 'Payone',
        value: 'payone',
        helpText: 'PayOne (ehem. Six): Sie finden die Merchant ID als „VU-Nr.“ auf einer Abrechnungsinformation unter „Details zur Abrechnung”. Die 9-stellige Nummer beginnt mit 24xxxxxxx, 25xxxxxxx oder 30xxxxxxx.',
        country: ['de', 'at']
    },
    {
        name: 'SumUP',
        value: 'sumup',
        helpText: '',
        country: ['de', 'at']
    },
    {
        name: 'Anderer',
        value: 'other',
        helpText: 'Ist Ihr Zahlungsdienstleister nicht aufgeführt, geben Sie uns im Feld "Merchant ID" bitte einfach den Namen Ihres Dienstleisters und die Merchant ID bekannt.',
        country: ['international']
    },
    {
        name: 'Keine Kartenzahlung möglich',
        value: 'na',
        helpText: 'Wenn keine Kartenzahlungen möglich sind, wird kein Vertrag für Karten erstellt.',
        country: ['international'],
    },

    {
        name: 'B und S Service GmbH',
        value: 'bUndSServiceGmbH',
        helpText: '',
        country: ['de'],
    },
    {
        name: 'DZ Bank',
        value: 'dzBank',
        helpText: '',
        country: ['de'],
    },
    {
        name: 'Elavon Financial Services',
        value: 'elavonFinancialServices',
        helpText: '',
        country: ['de'],
    },
    {
        name: 'Heidelpay',
        value: 'heidelpay',
        helpText: '',
        country: ['de'],
    },
    {
        name: 'Ingencio PAyment Services GmbH',
        value: 'ingencio',
        helpText: '',
        country: ['de'],
    },
    {
        name: 'InterCard AG',
        value: 'interCardAg',
        helpText: '',
        country: ['de'],
    },
    {
        name: 'Paysquare S.E.',
        value: 'paySquareSe',
        helpText: '',
        country: ['de'],
    },
    {
        name: 'SixPay S.A. (alt)',
        value: 'sixPayOld',
        helpText: '',
        country: ['de'],
    },
    {
        name: 'SixPay S.A. (neu)',
        value: 'sixPayNew',
        helpText: '',
        country: ['de'],
    },
    {
        name: 'Unzer',
        value: 'unzer',
        helpText: '',
        country: ['de'],
    },
];


export const getPaymentProvidersByCountry = (country) => {
    const countrySpecificProviders = PAYMENT_PROVIDERS.filter((provider) => provider.country.includes(country));
    const internationalProviders = PAYMENT_PROVIDERS.filter((provider) => provider.country.includes('international'));
    return [...countrySpecificProviders, ...internationalProviders];
};