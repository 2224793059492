import validate from '../../constants/Validator';
import {firewallReplacement} from "../../constants/Helpers";

export const ADD_CONTACT_PERSON = 'ADD_CONTACT_PERSON'
export const GET_CONTACT_PERSON = 'GET_CONTACT_PERSON'
export const DELETE_CONTACT_PERSON = 'DELETE_CONTACT_PERSON'
export const SET_CONTACT_PERSON_FIELD = 'SET_CONTACT_PERSON_FIELD'
export const SET_CONTACT_PERSONS = 'SET_CONTACT_PERSONS'
export const CHANGE_CONTACT_TAG = 'CHANGE_CONTACT_TAG'
export const SET_CONTACT_ERRORS = 'SET_CONTACT_ERRORS'


const constraints = {
    de_AT: {
        salutation: {
            firewallChars: true,
            length: {
                minimum: 1,
                message: "^Bitte wählen Sie eine Anrede aus."
            },
            format: {
                pattern: "^H|F$",
                flags: "i",
                message: "^Bitte wählen Sie eine Anrede aus."
            }
        },
        firstname: {
            firewallChars: true,
            length: {
                minimum: 1,
                message: "^Dieses Feld darf nicht Leer sein."
            },
        },
        lastname: {
            firewallChars: true,
            length: {
                minimum: 1,
                message: "^Dieses Feld darf nicht Leer sein."
            },
        },
        email: {
            firewallChars: true,
            email: {
                message: "^Bitte geben Sie eine gültige E-Mail Adresse an."
            }
        },
        phone: {
            firewallChars: true,
            phoneNumber: {
                country: 'AT',
                message: "^error.validPhoneNumber"
            },
        },
        tags: {
            firewallChars: true,
            length: {
                minimum: 1,
                message: "^Bitte geben Sie mindestens 1 Zuständigkeit an."
            },
        },
    },
    de_DE: {
        phone: {
            firewallChars: true,
            phoneNumber: {
                country: 'DE',
                message: "^error.validPhoneNumber"
            },
        },
    }

}

export function getConstraint(langKey = 'de_AT'){
    return {...constraints['de_AT'], ...constraints[langKey]}
}


export const getContactPerson = () => ({
    type: GET_CONTACT_PERSON,
});

export const addContactPerson = (data) => ({
    type: ADD_CONTACT_PERSON,
    data
});

export const deleteContactPerson = (data,langKey) => ({
    type: DELETE_CONTACT_PERSON,
    data
});

export const setContactSalutation = (i, data) => ({
    type: SET_CONTACT_PERSON_FIELD,
    i,
    field: 'salutation',
    data
});

export const setContacts = (data, langKey) => ({
    type: SET_CONTACT_PERSONS,
    data,
    langKey
});

export const setContactFirstname = (i, data) => ({
    type: SET_CONTACT_PERSON_FIELD,
    i,
    field: 'firstname',
    data
});

export const setContactLastname = (i, data) => ({
    type: SET_CONTACT_PERSON_FIELD,
    i,
    field: 'lastname',
    data
});

export const setContactEmail = (i, data) => ({
    type: SET_CONTACT_PERSON_FIELD,
    i,
    field: 'email',
    data
});

export const setContactPhone = (i, data, langKey) => ({
    type: SET_CONTACT_PERSON_FIELD,
    i,
    field: 'phone',
    data,
    langKey
});


export const changeContactTag = (i, data, langKey) => ({
    type: CHANGE_CONTACT_TAG,
    i,
    data,
    langKey
});

export const setContactPeronsErrors = (data) => ({
    type: SET_CONTACT_ERRORS,
    data,
});


export function validateContactPersons(contacts, langKey) {

    let errors = [];
    let currentValidation = null;
    let countMainTags = 0;
    let errorCount = 0;


    contacts.forEach(function (v, k) {
        countMainTags += v.data.tags.includes('main') ? 1 : 0;
    })


    contacts.forEach(function (v, k) {

        if (validate.isEmpty(v.data.phone)) {
            delete v.data['phone'];
        }

        currentValidation = validate({...v.data},getConstraint(langKey));

        if (countMainTags === 0) {
            if (currentValidation === undefined) {
                currentValidation = [];
            }
            currentValidation['tags'] = ['Mindestens 1 Person muss als Hauptkontakt angegeben werden.'];
        } else if (countMainTags > 1) {
            if (currentValidation === undefined) {
                currentValidation = [];
            }
            currentValidation['tags'] = ['Es darf nur 1 Person als Hauptkontakt angegeben werden.'];
        }

        errors.push(currentValidation);

        if (currentValidation !== undefined) {
            errorCount++;
        }
    })

    if (errorCount === 0) {
        return undefined;
    }

    return errors;
}

const errorObj = {
    error: {
        salutation: false,
        firstname: false,
        lastname: false,
        email: false,
        phone: false,
        tags: false,
    }
}

const initialState = [{
    data: {
        id: '',
        salutation: '',
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        tags: [],
    },
    ...errorObj
}];

export default (state = initialState, action) => {

    const {data, i, field, langKey = 'de_AT'} = action;
    let validation = null;
    let validatedContacts = null;
    let copyInitialState = JSON.parse(JSON.stringify(initialState[0]));
    let skipValidation = false;

    switch (action.type) {
        case SET_CONTACT_PERSONS:
            state = data.map(function (v, i) {
                return {data: v, error: {...state['error']}};
            });

            validatedContacts = validateContactPersons(state,langKey)
            state.forEach(function (v, i) {
                if (validatedContacts !== undefined) {
                    state[i].error = {...errorObj.error, ...validatedContacts[i]};
                }
            })


            return [...state];
        case ADD_CONTACT_PERSON:
            if (data === undefined) {
                state.push(copyInitialState);
                return [...state]
            }

            return [{...state, data}];
        case SET_CONTACT_PERSON_FIELD:

            if (field === 'phone' && validate.isEmpty(data)) {
                skipValidation = true;
            }

            if (skipValidation === false) {
                let validationObj = {[field]: data};
                validation = validate(validationObj, getConstraint(langKey));
                state[i]["error"][field] = validation ? state[i]["error"][field] = validation[field].join(', ') : false;
                state[i]['data'][field] = firewallReplacement(data);
            } else {
                state[i]["error"][field] = false;
            }

            return [...state];

        case CHANGE_CONTACT_TAG:
            let tags = state[i]['data']['tags'];

            if (tags.includes(data)) {
                // remove existing tag
                tags = tags.filter(function (v, k) {
                    return v !== data;
                });
            } else {
                // add tag
                tags.push(data);
            }

            state[i]['data']['tags'] = tags;
            validatedContacts = validateContactPersons(state, langKey)
            if (validatedContacts !== undefined) {
                state.forEach(function (v, i) {

                    if (validatedContacts[i] === undefined) {
                        state[i].error = {...errorObj.error};
                    } else {
                        if (validatedContacts[i].hasOwnProperty('tags')) {
                            state[i].error = {...errorObj.error, tags: validatedContacts[i]['tags']};
                        } else {
                            state[i].error = {...errorObj.error};
                        }
                    }

                })
            }

            return [...state];
        case DELETE_CONTACT_PERSON:
            // delete by key
            let newState = state.filter(function (v, k) {
                return k !== data;
            });

            // add empty entry if all where deleted
            if (newState.length === 0) {
                newState.push(copyInitialState);
            }

            validatedContacts = validateContactPersons(newState, langKey);
            newState.forEach(function (v, i) {
                if (validatedContacts !== undefined) {
                    state[i].error = {...errorObj.error, ...validatedContacts[i]};
                }
            })

            return [...newState]
        case SET_CONTACT_ERRORS:
            state.forEach(function (v, i) {
                state[i].error = {...errorObj.error, ...data[i]};
            })
            return [...state];

        default:
            return state;
    }
}
