import {call, cancelled, delay, put} from 'redux-saga/effects';
import {setMaintenanceMode} from "../reducers/Maintenance";
import {getMaintenance} from "../request/Maintenance";
import axios from 'axios';
import {store} from "../store";


export function* handleGetMaintenance() {
    let intervalId;
    const source = axios.CancelToken.source();
    try {
        while (true) {
            const country = store.getState().country.country;
            const response = yield call(getMaintenance, {cancelToken: source.token}, country);
            const {data, status} = response;
            yield put(setMaintenanceMode(data.maintenance));
            if (data.maintenance) {
                yield delay(5000); // wait for 5 seconds
            } else {
                if (intervalId) {
                    clearInterval(intervalId); // clear the interval if not in maintenance mode
                }
                break;
            }
        }
    } catch (e) {
        console.debug(e);
        if (!axios.isCancel(e) || e === undefined) {
            yield put(setMaintenanceMode(true));
        }
    } finally {
        if (yield cancelled()) {
            source.cancel();
            if (intervalId) {
                clearInterval(intervalId); // clear interval on cancellation
            }
        }
    }
}