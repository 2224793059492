import {takeLatest,takeEvery} from 'redux-saga/effects';
import {handleGetCompanyData} from "./handlers/Company";
import {handleGetFormData, handleSaveForm} from "./handlers/Form";
import {GET_COMPANY_DATA} from './reducers/CompanyData'
import {handleGetContacts} from "./handlers/Contacts";
import {GET_CONTACTS} from "./reducers/Contacts";
import {GET_CARD_CONTRACT, GET_CHILDREN_CONTRACT, GET_VOUCHER_CONTRACT} from "./reducers/Agreement";
import {GET_VERIFY_CODE} from "./reducers/Verify";
import {handleSendVerifyMail} from "./handlers/Verify";
import {GET_CONTACT_PERSON} from "./reducers/ContactPersons";
import {handleGetContactPerson} from "./handlers/ContactPersons";
import {
    handleRequestGetCardContract,
    handleRequestGetChildrenContract,
    handleRequestGetVoucherContract
} from "./handlers/Agreement";
import {GET_FORM_DATA, SAVE_FORM_DATA} from "./reducers/Form";
import {GET_SERVICES} from "./reducers/Services";
import {handleGetServices} from "./handlers/Services";
import {handleGetAffiliate} from "./handlers/Affiliate";
import {GET_AFFILIATE} from "./reducers/Affiliate";
import {GET_MAINTENANCE_MODE} from "./reducers/Maintenance";
import {handleGetMaintenance} from "./handlers/Maintenance";
import {DETECT_COUNTRY, SET_COUNTRY} from "./reducers/CountryDetector";
import {handleDetectCountry} from "./handlers/Country";

export function* watcherSaga() {
    yield takeLatest(GET_COMPANY_DATA, handleGetCompanyData);
    yield takeLatest(GET_CONTACT_PERSON, handleGetContactPerson);
    yield takeLatest(GET_CONTACTS, handleGetContacts);
    yield takeLatest(GET_VERIFY_CODE, handleSendVerifyMail);
    yield takeLatest(GET_CHILDREN_CONTRACT, handleRequestGetChildrenContract);
    yield takeLatest(GET_CARD_CONTRACT, handleRequestGetCardContract);
    yield takeLatest(GET_VOUCHER_CONTRACT, handleRequestGetVoucherContract);
    yield takeLatest(GET_FORM_DATA, handleGetFormData);
    yield takeLatest(SAVE_FORM_DATA, handleSaveForm);
    yield takeLatest(GET_SERVICES, handleGetServices);
    yield takeLatest(GET_AFFILIATE, handleGetAffiliate);
    yield takeLatest(GET_MAINTENANCE_MODE, handleGetMaintenance);
    yield takeLatest(SET_COUNTRY, handleDetectCountry);
}
