import validate from "../../constants/Validator";


export const SET_AP_NUMBER = 'SET_AP_NUMBER'
export const SET_AP_NUMBER_ERROR = 'SET_AP_NUMBER_ERROR'
export const SET_AP_NUMBER_LOADING = 'SET_AP_NUMBER_LOADING'


export const setApNumber = (apNumber, langKey) => ({
    type: SET_AP_NUMBER, apNumber, langKey
});

export const setApNumberError = (error) => ({
    type: SET_AP_NUMBER_ERROR, error
});

export const setApNumberLoading = (loading) => ({
    type: SET_AP_NUMBER_LOADING, loading
});

const constraints = {
    de_AT: {
        apNumber: {
            format: {
                pattern: "^[3|4][0-9]{4}$", flags: "i", message: "^Bitte geben Sie eine gültige AP-Nummer an."
            }
        }
    },
    de_DE: {
        apNumber: {
            format: {
                pattern: "^[0-9]{6}-[0-9]{3}$",
                flags: "i",
                message: "^Bitte geben Sie eine gültige AP-Nummer im Format  000000-000 an."
            }
        },
    }
}

export function getConstraint(langKey = 'de_AT') {
    return {...constraints['de_AT'], ...constraints[langKey]}
}

const initialState = {
    apNumber: '', error: '', loading: false
}

export default (state = initialState, action) => {
    const {data, status, apNumber, error, loading, langKey = 'de_AT'} = action;
    let validation = null;
    let validationObj = {};
    switch (action.type) {
        case SET_AP_NUMBER:
            validationObj = {['apNumber']: apNumber};
            validation = validate(validationObj, getConstraint(langKey));
            state["error"] = validation ? state["error"] = validation['apNumber'].join(', ') : false;

            state['apNumber'] = apNumber;
            return {...state};
        case SET_AP_NUMBER_ERROR:
            state['error'] = error;
            return {...state};
        case SET_AP_NUMBER_LOADING:
            state['loading'] = loading;
            return {...state};
        default:
            return state;
    }
}
