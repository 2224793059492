import {call, put} from 'redux-saga/effects';
import {requestGetCardContract, requestGetChildrenContract, requestGetVoucherContract} from "../request/Agreement";
import {openAgreementModal} from "../reducers/Agreement";
import {setError} from "../reducers/Error";

const ContractError = 'Leider konnte der Vertrag nicht generiert werden. Bitte stellen Sie sicher, dass alle erforderlichen Daten korrekt eingegeben wurden und versuchen Sie es erneut. Sollte das Problem weiterhin auftreten, wenden Sie sich bitte an unseren Support.';

export function* handleRequestGetVoucherContract(action) {
    try {
        const response = yield call(requestGetVoucherContract, action.data);
        const {data} = response;
        yield put(openAgreementModal(data, 'Gutscheinvertrag'));
    } catch (e) {
        yield put(setError(ContractError));
    }
}

export function* handleRequestGetChildrenContract(action) {
    try {
        const response = yield call(requestGetChildrenContract, action.data);
        const {data} = response;
        yield put(openAgreementModal(data, 'Kinderbetreuungs Pass Vertrag'));
    } catch (e) {
        yield put(setError(ContractError));
    }
}

export function* handleRequestGetCardContract(action) {

    try {
        const response = yield call(requestGetCardContract, action.data);
        const {data} = response;
        yield put(openAgreementModal(data, 'Kartenvertrag'));
    } catch (e) {
        yield put(setError(ContractError));
    }
}




