import {all, put} from 'redux-saga/effects';
import {getMaintenanceMode} from "../reducers/Maintenance";
import {getAffiliate} from "../reducers/Affiliate";
import TagManager from "react-gtm-module";
import {tagManagerArgs} from "../../constants/Config";


export function* handleDetectCountry() {
    const query = new URLSearchParams(window.location.search);
    const affiliateCode = query.get('affiliatecode') || 'default';
    TagManager.initialize(tagManagerArgs);

    yield all([
        put(getMaintenanceMode()),
        put(getAffiliate(affiliateCode))
    ]);
}
