import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import translations from "../../constants/Translation";

export const GET_COUNTRY = 'GET_COUNTRY'
export const SET_COUNTRY = 'SET_COUNTRY'

export const getCountry = () => ({
    type: GET_COUNTRY
});

export const setCountry = (payload) => ({
    type: SET_COUNTRY, payload
});

const initialState = {
    country: undefined,
    language: 'de_AT',
    i18n: null,
}


export default (state = initialState, action) => {
    const {payload} = action;
    switch (action.type) {
        case SET_COUNTRY:

            if (state.i18n === null) {
                i18n
                    .use(initReactI18next)
                    .init({
                        debug: process.env.NODE_ENV === 'development',
                        resources: translations,
                        lng: 'de_At',
                        fallbackLng: "de_AT",
                        returnObjects: true,
                        interpolation: {
                            escapeValue: false
                        }
                    });
            }

            let language = 'de_' + payload.toUpperCase();
            if (!i18n.language === language || state.i18n === null) {
                i18n.changeLanguage(language)
            }

            return {...state, country: payload, language, i18n}
        default:
            return state;
    }
}
