import {useEffect} from "react";
import TagManager from "react-gtm-module";
import {tagManagerArgs} from "../../constants/Config";

export function registerErrorHandlers() {

    function report(event) {

        // @TODO
        console.debug(event);

        let dataLayer = {
            error: event
        };
        TagManager.initialize({...tagManagerArgs, dataLayer});
    }

    window.removeEventListener("error", report)
    window.addEventListener("error", report)
}
