import {call, put} from 'redux-saga/effects';
import {getServices} from "../reducers/Services";
import {setProducts} from "../reducers/Places";


export function* handleGetServices() {
    try {
        const response = yield call(getServices);
        const {data} = response;
        yield put(setProducts(data));
    } catch (e) {

    }
}
