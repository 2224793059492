import React, {Suspense, useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import {Route, Routes} from 'react-router'
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import {ThemeProvider} from '@mui/material/styles';
import {history, persistor} from './redux/store';
import {PersistGate} from 'redux-persist/integration/react'
import axios from 'axios';
import './App.scss';
import {ReduxRouter} from '@lagunovsky/redux-react-router'
import {API_TOKEN} from "./constants/Config";
import {registerErrorHandlers} from './Components/error/ReportError'
import {setBearerToken} from "./redux/reducers/Token";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {setError} from "./redux/reducers/Error";
import LoadingPage from "./Components/Pages/LoadingPage";
import {ReactComponent as CloseIcon} from './assets/img/close.svg';
import {ContactPage, FinishPage, MaintenancePage, VerifyPage, WelcomePage} from "./Components/Pages/";
import {StyledFooterLi, StyledFooterUl, theme} from "./constants/Style";
import MyAppBar from "./MyAppBar";
import {debounce} from 'lodash';
import {setCountry} from "./redux/reducers/CountryDetector";
import i18n from "i18next";
import {I18nextProvider, initReactI18next, useTranslation} from "react-i18next";
import translations from "./constants/Translation";


registerErrorHandlers();


function App(props) {
    const dispatch = useDispatch();
    const bearerToken = useSelector((state) => state.token.token);
    const maintenance = useSelector((state) => state.maintenance.isMaintenanceMode);
    const {t} = useTranslation();



    const debouncedInputChange = useCallback(debounce((value) => {
        dispatch(setError(value));
    }, 300), []);



    if (bearerToken) {
        axios.defaults.headers.common['Authorization'] = bearerToken;
    }

    useEffect(() => {
        // axios global config


        // get bearer token
        axios.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data

            if (response.headers.hasOwnProperty('authorization') && response.headers.authorization) {
                axios.defaults.headers.common['Authorization'] = response.headers.authorization;
                dispatch(setBearerToken(response.headers.authorization))
            }


            return response;
        }, function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error


            if (error.response === undefined) {
                debouncedInputChange('Es ist ein Netzwerk Fehler aufgetreten bitte versuchen Sie es später erneut.')
                return Promise.reject(error);
            }

            if (error.config.method) {

                switch (error.response.status) {
                    case 401:
                        debouncedInputChange(setError('Sie sind nicht berechtigt.'));
                        break;
                    case 400:
                        debouncedInputChange(setError('Es ist ein Fehler aufgetreten bitte überprüfen Sie Ihre Daten und versuchen Sie es später erneut.'));
                        break;
                    case 500:
                        debouncedInputChange(setError('Es ist ein unerwarteter Fehler aufgetreten bitte versuchen Sie es später erneut.'));
                        break;
                    default:
                        break;
                }
            }

            return Promise.reject(error);
        });
    }, []);


    const CloseButton = ({closeToast}) => (<CloseIcon onClick={closeToast} style={{
        width: '28px', height: '28px', minWidth: '28px', marginTop: 'auto', marginBottom: 'auto'
    }}/>);


    return (<ThemeProvider theme={theme}>
        <PersistGate loading={<LoadingPage/>} persistor={persistor}>
            <CssBaseline/>
            {/* Header start */}
            <MyAppBar/>

            {/* Header end */}
            {/* Content start */}
            <Container id="main" component="main"
                       maxWidth={'xl'}
                       xs={12}
                       sx={{'mt': '24px'}}>
                <Box minHeight={'720px'}>
                    <ToastContainer
                        closeButton={CloseButton}
                    />
                    <I18nextProvider i18n={i18n}>
                        <ReduxRouter history={history}> { /* place ConnectedRouter under Provider */}
                            <Suspense fallback={<LoadingPage/>}>
                                {maintenance === true && <MaintenancePage/>}
                                {maintenance === false && <Routes>
                                    <Route exact={false} path="/verify/:token" element={<VerifyPage/>}/>
                                    <Route path="/finish" element={<FinishPage/>}/>
                                    <Route path="/contact" element={<ContactPage/>}/>
                                    <Route path="/" element={<WelcomePage/>}/>
                                </Routes>}
                            </Suspense>
                        </ReduxRouter>
                    </I18nextProvider>
                </Box>
            </Container>
            {/* Content end */}
            {/* Footer start */}
            <Container
                sx={{
                    maxWidth: '100% !important',
                    marginTop: 'calc(1.6% + 72px)',
                    bottom: 0,
                    position: {
                        xs: 'relative',
                        sm: 'relative',
                        md: 'relative',
                        xl: 'sticky'
                    },
                    zIndex: 10
                }}
                component="footer">
                <Grid container sx={{maxWidth: '100%'}} margin={'auto'}>
                    <Grid xs={12} xl={11} item>
                        <StyledFooterUl disablePadding={true}>
                            <StyledFooterLi>
                                <Typography variant="body" align="left">
                                    <Typography variant="caption" component='span'>
                                        <b> {'©'}
                                            <Link sx={{textDecoration: 'none'}} color="inherit"
                                                  target="_blank"
                                                  rel="noopener"
                                                  style={{fontSize: '12px'}}
                                                  href="https://www.pluxee.at/">
                                                Pluxee
                                            </Link>{' '}
                                            {new Date().getFullYear()}
                                        </b>
                                    </Typography>
                                </Typography>
                            </StyledFooterLi>
                            <StyledFooterLi>
                                <Link sx={{textDecoration: 'none'}} target="_blank" rel="noopener"
                                      color="inherit"
                                      href={t('links.about')}>
                                    <Typography variant="caption" component='span'>
                                        Über Pluxee

                                    </Typography>
                                </Link>
                            </StyledFooterLi>
                            <StyledFooterLi>
                                <Link sx={{textDecoration: 'none'}} target="_blank" rel="noopener"
                                      color="inherit"
                                      href={t('links.codex')}>
                                    <Typography variant="caption" component='span'>
                                        Verhaltenskodex
                                    </Typography>
                                </Link>
                            </StyledFooterLi>
                            <StyledFooterLi>
                                <Link sx={{textDecoration: 'none'}} target="_blank" rel="noopener"
                                      color="inherit"
                                      href={t('links.impressum')}>
                                    <Typography variant="caption" component='span'>
                                        Impressum
                                    </Typography>
                                </Link>
                            </StyledFooterLi>
                            <StyledFooterLi>
                                <Link sx={{textDecoration: 'none'}} target="_blank" rel="noopener"
                                      color="inherit"
                                      href={t('links.gdpr')}>
                                    <Typography variant="caption" component='span'>
                                        Datenschutz
                                    </Typography>
                                </Link>
                            </StyledFooterLi>
                            <StyledFooterLi>
                                <Link sx={{textDecoration: 'none'}} target="_blank" rel="noopener"
                                      color="inherit"
                                      href={t('links.cookie')}>
                                    <Typography variant="caption" component='span'>
                                        Cookie Richtlinie
                                    </Typography>
                                </Link>
                            </StyledFooterLi>
                            <StyledFooterLi>
                                <Link id='cookie-settings' sx={{textDecoration: 'none'}}
                                      target="_self"
                                      rel="noopener"
                                      color="inherit"
                                      onClick={(e) => window.Optanon.ToggleInfoDisplay()}
                                      href="#cookie-settings">
                                    <Typography variant="caption" component='span'>
                                        Cookie Einstellungen
                                    </Typography>
                                </Link>
                            </StyledFooterLi>
                        </StyledFooterUl>
                    </Grid>
                    <Grid xs={12} xl={1} item>
                        <StyledFooterUl>
                            <StyledFooterLi sx={{marginLeft: 'auto', marginRight: 'auto'}}>
                                <Typography variant="caption" component='span'>
                                    Version 1.4
                                </Typography>
                            </StyledFooterLi>
                        </StyledFooterUl>
                    </Grid>
                </Grid>
            </Container>
            {/* Footer end */}
        </PersistGate>
    </ThemeProvider>);
}

export default App;
