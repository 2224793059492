import ProductKabImage from "../assets/img/KAB.png";
import ProductRpImage from "../assets/img/RGS-RK.png";
import ProductLpImage from "../assets/img/LGS-LK.png";
import ProductGpImage from "../assets/img/GGS-GK.png";

export const productsConfiguration = {
    de_AT: [{
        name: 'kb',
        title: 'Klimabonus',
        description: 'Unterstützungsleistung des Bundesministeriums für Klimaschutz: Bei allen teilnehmenden Betrieben auf alle Waren und Dienstleistungen einlösbar.',
        image: '',
        src: ProductKabImage,
        helpText: 'Umsatzabhängig: {service_fee}% von den eingereichten Gutscheinwerten',
        disabledHelpText: 'Umsatzabhängig: {service_fee}% von den eingereichten Gutscheinwerten',
        disabledExistLockText: 'Sie können dieses Produkt nicht abwählen, da bereits ein aktiver Vertrag besteht.',
        disabledExistText: 'Sie können dieses Produkt ohne Zusatzkosten zu den bisherigen Konditionen annehmen.',
        expanded: false,
        selected: false,
        tags: ['Gutschein'],
        serviceMapping: ['gastro', 'restaurant', 'other-services', 'children'],
    }, {
        name: 'rp',
        title: 'Restaurant Pass',
        description: 'Voraussetzung für den Vertragsabschluss ist eine aufrechte Gastgewerbeberechtigung.\n' + 'Falls auch ein Lebensmittelhandel betrieben wird, müssen beide Bereiche eine getrennte Kassenführung aufweisen.\n' + 'Zur Bezahlung von Mahlzeiten.',
        image: '',
        src: ProductRpImage,
        helpText: 'Umsatzabhängig: {service_fee}% von den eingereichten Gutscheinwerten bzw. Kartentransaktionswerten',
        disabledHelpText: 'Nur für Gastronomiebetriebe möglich.',
        disabledExistLockText: 'Sie können dieses Produkt nicht abwählen, da bereits ein aktiver Vertrag besteht.',
        disabledExistText: 'Sie können dieses Produkt ohne Zusatzkosten zu den bisherigen Konditionen annehmen.',
        expanded: false,
        selected: false,
        tags: ['Gutschein', 'Karte'],
        serviceMapping: ['gastro'],
    }, {
        name: 'lp',
        title: 'Lebensmittel Pass',
        description: 'Voraussetzung für den Vertragsabschluss ist eine Gewerbeberechtigung zum Lebensmittelhandel oder Gastgewerbeberechtigung',
        image: '',
        src: ProductLpImage,
        helpText: 'Umsatzabhängig: {service_fee}% von den eingereichten Gutscheinwerten bzw. Kartentransaktionswerten',
        disabledHelpText: 'Nur für Gastronomiebetriebe oder den Lebensmittelhandel möglich.',
        disabledExistLockText: 'Sie können dieses Produkt nicht abwählen, da bereits ein aktiver Vertrag besteht.',
        disabledExistText: 'Sie können dieses Produkt ohne Zusatzkosten zu den bisherigen Konditionen annehmen.',
        expanded: false,
        selected: false,
        tags: ['Gutschein', 'Karte'],
        serviceMapping: ['gastro', 'restaurant']
    }, {
        name: 'gp',
        title: 'Geschenk Pass',
        description: 'Für alle Waren und Dienstleistungen (ausgenommen Alkohol) verwendbar',
        image: '',
        src: ProductGpImage,
        helpText: 'Umsatzabhängig: {service_fee}% von den eingereichten Gutscheinwerten',
        disabledHelpText: 'Nur für Gastronomiebetriebe, Lebensmittelhandel oder Dienstleistungen anderer Art möglich.',
        disabledExistLockText: 'Sie können dieses Produkt nicht abwählen, da bereits ein aktiver Vertrag besteht.',
        disabledExistText: 'Sie können dieses Produkt ohne Zusatzkosten zu den bisherigen Konditionen annehmen.',
        expanded: false,
        selected: false,
        tags: ['Gutschein', 'Karte'],
        serviceMapping: ['gastro', 'restaurant', 'other-services']
    }],

    de_DE: [{
        name: 'rp',
        title: 'Restaurant Gutschein',
        description: 'Voraussetzung für den Vertragsabschluss ist eine aufrechte Gastgewerbeberechtigung.\n' + 'Falls auch ein Lebensmittelhandel betrieben wird, müssen beide Bereiche eine getrennte Kassenführung aufweisen.\n' + 'Zur Bezahlung von Mahlzeiten.',
        image: '',
        src: ProductRpImage,
        helpText: 'Umsatzabhängig: {service_fee}% von den eingereichten Gutscheinwerten bzw. Kartentransaktionswerten',
        disabledHelpText: 'Nur für Gastronomiebetriebe möglich.',
        disabledExistLockText: 'Sie können dieses Produkt nicht abwählen, da bereits ein aktiver Vertrag besteht.',
        disabledExistText: 'Sie können dieses Produkt ohne Zusatzkosten zu den bisherigen Konditionen annehmen.',
        expanded: false,
        selected: false,
        tags: ['Gutschein'],
        serviceMapping: ['gastro'],
    }, {
        name: 'gpc',
        title: 'Benefits Karte ',
        description: 'Für alle Waren und Dienstleistungen (ausgenommen Alkohol) verwendbar',
        image: '',
        src: ProductLpImage,
        helpText: 'Umsatzabhängig: {service_fee}% von den eingereichten Gutscheinwerten',
        disabledHelpText: 'Nur für Gastronomiebetriebe, Lebensmittelhandel oder Dienstleistungen anderer Art möglich.',
        disabledExistLockText: 'Sie können dieses Produkt nicht abwählen, da bereits ein aktiver Vertrag besteht.',
        disabledExistText: 'Sie können dieses Produkt ohne Zusatzkosten zu den bisherigen Konditionen annehmen.',
        expanded: false,
        selected: false,
        tags: ['Karte'],
        serviceMapping: ['gastro', 'restaurant', 'other-services', 'children']
    }]
};

export default productsConfiguration;