import axios from 'axios';
import {API_URL} from '../../constants/Config'

export function requestGetCompanyData() {
    return axios.request({
        method: 'get',
        url: API_URL + '/company',
    })
}

