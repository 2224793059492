//https://www.youtube.com/watch?v=1K26DIKt3w8
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import createSagaMiddleware from "redux-saga";
import {watcherSaga} from "./rootSaga";
import {createBrowserHistory} from "history";
import {createRouterMiddleware, createRouterReducer} from '@lagunovsky/redux-react-router'
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage/session';
import {customReducers} from "./reducers";
import {syncTranslationWithStore,} from "react-redux-i18n";
import {setCountry} from "./reducers/CountryDetector";
import axios from "axios";
import {API_TOKEN, COUNTRY_SWITCH_ENABLED} from "../constants/Config";

export const history = createBrowserHistory();

const reducers = combineReducers({
    router: createRouterReducer(history),
    ...customReducers
});


const persistConfig = {
    key: 'root',
    version: 2,
    storage,
    blacklist: ['country', 'router', 'verify', 'contacts', 'agreement', 'formStatus'], // What you don't want to persist
    whitelist: ['company', 'step', 'token', 'palaceModal', 'contactPersons', 'companyData', 'services', 'places', 'formStatus', 'delete', 'affiliate', 'i18n'] // What you want to persist
}

const persistedReducer = persistReducer(persistConfig, reducers)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

export const store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(createRouterMiddleware(history), ...middlewares)));
syncTranslationWithStore(store);
sagaMiddleware.run(watcherSaga);

// country detection by top level domain
const getTopLevelDomainCountry = (url) => {
    const match = url.match(/\.([a-z]{2})(?::\d+)?(?:$|\/)/i);
    return match ? match[1] : 'de';
}

let country = typeof window !== 'undefined' ? getTopLevelDomainCountry(window.location.href) : 'de';
// start application

if (COUNTRY_SWITCH_ENABLED) {
    const storedCountry = sessionStorage.getItem('countrySwitch');
    if (storedCountry) {
        country = storedCountry;
    }
}

// add api key
axios.defaults.headers.common['API-KEY'] = API_TOKEN;

store.dispatch(setCountry(country));

export const persistor = persistStore(store);

export default store;
