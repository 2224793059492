import {all, call, cancelled, put} from 'redux-saga/effects';
import {getAffiliate} from "../request/Affiliate";
import {setAffiliate, setAffiliateStatus} from "../reducers/Affiliate";
import axios from 'axios';
import {store} from "../store";

export function* handleGetAffiliate(payload) {
    const source = axios.CancelToken.source();
    try {
        const country = store.getState().country.country;
        const response = yield call(getAffiliate, payload.affiliateCode, country, source.token);
        const {data, status} = response;
        yield all([
            put(setAffiliate(data)),
            put(setAffiliateStatus(true))
        ]);
    } catch (e) {
        console.debug(e);
    } finally {
        if (yield cancelled()) {
            source.cancel();
        }
    }
}
