const translations = {
    de_AT: {
        translation: {
            companyName: 'Pluxee Austria GmbH',
            welcome: "Willkommen beim Pluxee-Partnerprogramm Österreich",
            emails: {
                dataPrivacy: 'datenschutz.at@pluxee.com',
                contact: 'akzeptanzpartner.de@pluxee.com'
            },
            links: {
                about: 'https://www.pluxee.at/ueber-uns/',
                codex: 'https://www.pluxee.at/verhaltenskodex/',
                impressum: 'https://www.pluxee.at/impressum/',
                gdpr: 'https://www.pluxee.at/datenschutz/',
                cookie: 'https://www.pluxee.at/cookie-richtlinie/',
            },
            error: {
                validPhoneNumber: 'Bitte geben Sie eine Österreichische Telefonnummer an.'
            },

            fields: {
                uid: {
                    label: 'UID oder ZVR',
                    required: true,
                }
            }
        }
    }, de_DE: {
        translation: {
            emails: {
                dataPrivacy: 'datenschutz.de@pluxee.com',
                contact: 'akzeptanzpartner.de@pluxee.com'
            },
            companyName: 'Pluxee Deutschland GmbH',
            welcome: "Willkommen beim Pluxee-Partnerprogramm Deutschland", links: {
                about: 'https://www.pluxee.de/ueber-uns/',
                codex: 'https://www.pluxee.de/verhaltenskodex/',
                impressum: 'https://www.pluxee.de/impressum/',
                gdpr: 'https://www.pluxee.de/datenschutz/',
                cookie: 'https://www.pluxee.de/cookie-richtlinie/',
            },
            error: {
                validPhoneNumber: 'Bitte geben Sie eine Deutsche Telefonnummer an.'
            },
            fields: {
                uid: {
                    label: 'UID',
                    required: false,
                }
            }
        }
    },
};
export default translations;