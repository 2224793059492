import {all, call, put} from 'redux-saga/effects';
import {setVerifyLoading, setVerifyStatus} from "../reducers/Verify";
import {sendVerifyMail} from "../request/Verify";
import {setContactsError} from "../reducers/Contacts";
import {store} from "../store";

export function* handleSendVerifyMail(payload) {
    try {
        yield put(setVerifyLoading(true));
        const country = store.getState().country.country;
        const response = yield call(sendVerifyMail, payload.addressId, payload.apNumber, country);
        const {data, status} = response;
        yield all([
            put(setVerifyStatus(true)),
            put(setContactsError(false)),
        ]);
    } catch (e) {
        yield put(setVerifyStatus('done'));
    }

    yield put(setVerifyLoading(false));
}
